import React, { useEffect, useState } from 'react';
import { Card, Table, Button, Modal, Form, Input, Select, Spin } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { BASE_API_URL } from '../../util/constants';
import axios from 'axios';
import { useSelector } from 'react-redux';

const { Option } = Select;

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.user);
  //const [projects, setProjects] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (user.userType !== null) {
      setLoading(false);
    }
  }, [user]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/allUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      // Optionally handle errors, e.g., by showing a notification or message to the user
    }
  };

  // const fetchProjects = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_API_URL}/api/projectsId`);
  //     setProjects(response.data);
  //   } catch (error) {
  //     console.error('Failed to fetch projects:', error);
  //   }
  // };

  const showModal = (user) => {
    console.log(user);
    setEditingUser(user);
    setIsModalVisible(true);
  };

  const handleUpdate = async (values) => {
    try {
      await axios.post(`${BASE_API_URL}/api/users/${editingUser.firebase_id}`, values);
      setIsModalVisible(false);
      fetchUsers(); // Refresh the list after update
    } catch (error) {
      console.error('Failed to update user:', error);
      // Optionally handle errors, e.g., by showing a notification or message to the user
    }
  };

  const columns = [
    { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
    { title: 'User Type', dataIndex: 'user_type', key: 'user_type' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => <Button onClick={() => showModal(record)}>Edit</Button>,
    },
  ];

  if(loading) {
    return <Spin size="large" />;
  }

  if(user.userType === 'admin') {
    return (
      <>
        <Table dataSource={users} columns={columns} rowKey="id" />
        {editingUser && (
          <Modal title="Edit User" visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
            <div>
              <p>Email: {editingUser.email}</p>
            </div>
            <Form initialValues={editingUser} onFinish={handleUpdate}>
              <Form.Item name="first_name" label="First Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="last_name" label="Last Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="user_type" label="User Type" rules={[{ required: true }]}>
                <Select>
                  <Option value="basic">Basic</Option>
                  <Option value="admin">Admin</Option>
                  <Option value="superuser">Strategist</Option>
                </Select>
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form>
          </Modal>
        )}
      </>
    );
  }else{
    return (
      <Card
        style={{
          maxWidth: 600,
          margin: '20px auto',
          borderColor: '#ff4d4f' // You can adjust the color to match your design theme
        }}
        title={<span style={{ color: '#ff4d4f' }}><WarningOutlined /> Unauthorized Access</span>}
        bordered
      >
        <p style={{ color: '#595959', fontSize: '16px', textAlign: 'center' }}>
          You do not have permission to view this content. Please contact support if you believe this is an error.
        </p>
      </Card>
    );
    
  }
};

export default UsersManagement;
