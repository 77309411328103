import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import axios from 'axios';
import { BASE_API_URL } from '../util/constants';
//get user.userID from store

const AddMaintenancePage = () => {
  const { project_id } = useParams(); // Get project_id from the URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Get userID from the Redux store
  const userID = useSelector((state) => state.user.userID);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = {
        ...values,
        project_id,
        user_id: userID,
      };

      const response = await axios.post(`${BASE_API_URL}/api/maintenance/add`, data);
      notification.success({
        message: 'Success',
        description: 'Maintenance record added successfully!',
      });
      navigate('/maintenance'); // Redirect back to the main maintenance page
    } catch (error) {
      console.error('Error adding maintenance record:', error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while adding the maintenance record.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ marginBottom: 0, color: 'black', fontSize: '24px' }}>
        Add Maintenance Record for Project ID: {project_id}
      </h1>
      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          maintenance_checks: [],
          lighthouse_score_desktop_now: '',
          lighthouse_score_mobile_now: '',
        }}
      >
        <Form.Item name="maintenance_checks" label="Maintenance Checks">
          <Checkbox.Group>
            <Checkbox value="updated_wordpress_core">Updated WordPress Core</Checkbox>
            <Checkbox value="updated_plugins">Updated Plugins</Checkbox>
            <Checkbox value="updated_js_dependencies">Updated JS Dependencies</Checkbox>
            <Checkbox value="updated_php_dependencies">Updated PHP Dependencies</Checkbox>
            <Checkbox value="reviewed_user_accounts">Reviewed User Accounts</Checkbox>
            <Checkbox value="changed_admin_password">Changed Admin Password</Checkbox>
            <Checkbox value="reviewed_app_log">Reviewed App Log</Checkbox>
            <Checkbox value="reviewed_cron_log">Reviewed Cron Log</Checkbox>
            <Checkbox value="reviewed_error_log">Reviewed Error Log</Checkbox>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label="Lighthouse Score Desktop (Now)"
          name="lighthouse_score_desktop_now"
          rules={[{ required: true, message: 'Please enter Lighthouse Desktop Score!' }]}
        >
          <Input type="number" placeholder="Enter Lighthouse Desktop Score" />
        </Form.Item>

        <Form.Item
          label="Lighthouse Score Mobile (Now)"
          name="lighthouse_score_mobile_now"
          rules={[{ required: true, message: 'Please enter Lighthouse Mobile Score!' }]}
        >
          <Input type="number" placeholder="Enter Lighthouse Mobile Score" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Submit Maintenance Record
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddMaintenancePage;
