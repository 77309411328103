import React, { useEffect, useState } from 'react';
import { Table, Button, InputNumber, Input, Select, notification, Modal, Form } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { BASE_API_URL } from '../util/constants';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ScrumbersPage = () => {
  const [scrumbers, setScrumbers] = useState([]);
  const [filteredScrumbers, setFilteredScrumbers] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scrumberFilter, setScrumberFilter] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  // Get user details from the store
  const user = useSelector((state) => state.user);

  const userId = user?.userID;

  useEffect(() => {
    const fetchScrumbers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_API_URL}/api/scrumbers`);
        setScrumbers(response.data);
        setFilteredScrumbers(response.data);

        // Extract weekly data for the chart
        const currentWeek = response.data.filter((item) =>
          moment(item.date).isSame(moment(), 'week')
        );

        const weekly = currentWeek.reduce((acc, item) => {
          const userEntry = acc.find((entry) => entry.username === item.username);
          if (!userEntry) {
            acc.push({ username: item.username, scrumber: item.scrumber });
          }
          return acc;
        }, []);

        setWeeklyData(weekly);
      } catch (error) {
        console.error('Error fetching scrumbers:', error);
        notification.error({
          message: 'Error',
          description: 'Could not fetch scrumbers.',
        });
        setScrumbers([]);
        setFilteredScrumbers([]);
        setWeeklyData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchScrumbers();
  }, []);

  const handleAddClick = () => {
    setIsModalOpen(true);
    form.setFieldsValue({ user_id: userId });
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios.post(`${BASE_API_URL}/api/scrumbers/add`, values);
      notification.success({
        message: 'Success',
        description: 'Scrumber added successfully!',
      });
      setIsModalOpen(false);
      form.resetFields();

      const response = await axios.get(`${BASE_API_URL}/api/scrumbers`);
      setScrumbers(response.data);
      setFilteredScrumbers(response.data);
    } catch (error) {
      console.error('Error adding scrumber:', error);
      notification.error({
        message: 'Error',
        description: error.response.data.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = () => {
    const filtered = scrumbers.filter((item) => {
      return (
        (scrumberFilter ? item.scrumber === parseInt(scrumberFilter) : true)
      );
    });
    setFilteredScrumbers(filtered);
  };

  useEffect(handleFilterChange, [scrumberFilter]);

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Scrumber',
      dataIndex: 'scrumber',
      key: 'scrumber',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        const monday = moment(date).startOf('isoWeek');
        const sunday = moment(date).endOf('isoWeek');
        const formattedWeek = `${monday.format('MMM D, YYYY')} ~ ${sunday.format('MMM D, YYYY')}`;
        return formattedWeek;
      },
    },
  ];

  const chartData = {
    labels: weeklyData.map((item) => item.username),
    datasets: [
      {
        label: 'Scrumber',
        data: weeklyData.map((item) => item.scrumber),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Current Week Scrumbers',
      },
    },
    indexAxis: 'y', // Horizontal bar chart
  };

  return (
    <div style={{ padding: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <h1 style={{ marginBottom: 0, color: 'black', fontSize: '24px' }}>
          All Scrumbers
        </h1>
        <Button type="primary" onClick={handleAddClick}>
          Add
        </Button>
      </div>
      <div style={{ maxWidth:"900px", margin: 'auto', marginTop: '40px' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
      <Select
        placeholder="Filter by scrumber"
        value={scrumberFilter}
        onChange={(value) => setScrumberFilter(value)}
        style={{ width: '200px', marginBottom: '20px' }}
      >
        <Select.Option value="">All</Select.Option>
        {[1, 2, 3, 4, 5].map((num) => (
          <Select.Option key={num} value={num}>
            {num}
          </Select.Option>
        ))}
      </Select>
      <Table
        dataSource={filteredScrumbers}
        columns={columns}
        loading={loading}
        rowKey="id"
        locale={{
          emptyText:
            scrumbers.length === 0
              ? 'No data found. Add some Scrumbers!'
              : 'No matching data found.',
        }}
      />
      <Modal
        title="Add Scrumber"
        visible={isModalOpen}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label="User ID"
            hidden
            name="user_id"
            rules={[{ required: true, message: 'Please enter your username' }]}
          >
            <Input placeholder="Enter your username" />
          </Form.Item>
          <Form.Item
            label="Scrumber"
            name="scrumber"
            rules={[
              { required: true, message: 'Please enter a scrumber' },
              { type: 'number', min: 1, max: 5, message: 'Scrumber must be whole numbers between 1 and 5' },
            ]}
          >
            <InputNumber
                step={1}
                min={1} 
                max={5} 
                precision={0} 
                style={{width:'100%'}}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Submit Scrumber
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ScrumbersPage;
